import React from "react";
import PropTypes from "prop-types";

import ImageCard from "components/ImageCard";

const Competence = ({ className, frontmatter }) => {

  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader, imageFileName: rootImageFileName } = frontmatter;

  return (
    <div id="home" className="">
      <ImageCard
        imageFileName={rootImageFileName}
        header={rootHeader}
        subheader={rootSubHeader}
      />
    </div>
  );
};

Competence.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Competence.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Competence;
